<template>
  <div>
    <div class="page-header d-flex  align-items-center">
      <router-link :to="{ name: 'config_landing' }">
        <button class="btn btn-control me-2">
          <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
        </button>
      </router-link>
      <h3 class="page-header-title">New Mlo Landing Page</h3>
    </div>
    <div v-if="loading" class="text-center mt-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="page-content p-normal">

      <!-- Hero Section -->
      <fieldset v-if="header.status" class="p-3 border-bottom border-bottom-light">
        <div class="row mb-3">
          <div class="col-10">
            <h4>
              Hero Section:
            </h4>
          </div>
          <div class="col-2 text-end">
            <a :href="header.login_url" class="btn btn-primary btn-lg ml-2">User Login</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h1 class="mb-3">
              {{ header.title }}
            </h1>
            <p class="mb-3">
              {{ header.description }}
            </p>
            <a :href="header.btn_one.url" class="btn btn-success me-2">{{ header.btn_one.text }}</a>
            <a :href="header.btn_two.url" class="btn btn-dark">{{ header.btn_two.text }}</a>
          </div>
        </div>
      </fieldset>

      <!-- Why Choose Us Section -->
      <fieldset v-if="why_choose.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Why Choose Us:
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h1>
              {{ why_choose.title }}
            </h1>
            <ul v-for="(content, index) in why_choose.content" :key="index">
              <li class="list-group-item" v-html="content.text"></li>
            </ul>
          </div>
        </div>
      </fieldset>

      <!-- Current Rate Section -->
      <fieldset v-if="current_rate.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Current Rate:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ current_rate.title }}
              <span>
                  {{ current_rate.lowRate }}%
              </span>
              <span>
                  ({{ current_rate.highRate }}% {{ current_rate.updatedAt | formateMonth }})*
              </span>
            </h3>
            <a :href="current_rate.button.url" class="btn btn-warning">
              {{ current_rate.button.text }}
            </a>
          </div>
        </div>
      </fieldset>

      <!-- Loan Options Section -->
      <fieldset v-if="loan_option.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Loan Options:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ loan_option.title }}
            </h3>
            <ul v-for="(content, index) in loan_option.content" :key="index">
              <li class="list-group-item">
                <h4>{{ content.title }}</h4>
                <p>{{ content.description }}</p>
                <a :href="content.button.url" class="btn btn-dark">{{ content.button.text }}</a>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>

      <!-- How it works Section -->
      <fieldset v-if="how_it_work.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              How it works:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ how_it_work.title }}
            </h3>
            <ul v-for="(content, index) in how_it_work.content" :key="index">
              <li class="list-group-item">
                <h4>{{ content.title }}</h4>
                <p>{{ content.description }}</p>
              </li>
            </ul>
            <a :href="how_it_work.btn_one.url" class="btn btn-success me-2">{{ how_it_work.btn_one.text }}</a>
            <a :href="how_it_work.btn_two.url" class="btn btn-dark">{{ how_it_work.btn_two.text }}</a>
          </div>
        </div>
      </fieldset>

      <!-- Reviews Section -->
      <fieldset v-if="review.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Reviews:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ review.title }}
            </h3>
          </div>
        </div>
      </fieldset>

      <!-- Ratings Section -->
      <fieldset v-if="rating.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Ratings:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ rating.title }}
            </h3>
          </div>
        </div>
      </fieldset>

      <!-- Our lenders Section -->
      <fieldset v-if="lender.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Our lenders:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ lender.title }}
            </h3>
          </div>
        </div>
      </fieldset>

      <!-- Team of Experts Section -->
      <fieldset v-if="team.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Team of Experts:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ team.title }}
            </h3>
            <a :href="team.button.url" class="btn btn-dark">{{ team.button.text }}</a>
          </div>
        </div>
      </fieldset>

      <!-- Loan Counter Section -->
      <fieldset v-if="counter.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Loan Counter:
            </h4>
          </div>
          <div class="col-12">
            <h3>
              {{ counter.title }}
            </h3>
          </div>
        </div>
      </fieldset>

      <!-- Get In touch Section -->
      <fieldset v-if="get_in_touch.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Get In touch:
            </h4>
          </div>
          <div class="col-12">
            <h1 class="mb-3">
              {{ get_in_touch.title }}
            </h1>
            <p class="mb-3">
              {{ get_in_touch.description }}
            </p>
            <a :href="get_in_touch.btn_one.url" class="btn btn-success me-2">{{ get_in_touch.btn_one.text }}</a>
            <a :href="get_in_touch.btn_two.url" class="btn btn-dark">{{ get_in_touch.btn_two.text }}</a>
          </div>
        </div>
      </fieldset>

      <!-- FAQS Section -->
      <fieldset v-if="faqs.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              FAQS Section:
            </h4>
          </div>
          <div class="col-12">
            <h1 class="mb-3">
              {{ faqs.title }}
            </h1>
            <ul v-for="(content, index) in faqs.content" :key="index">
              <li class="list-group-item">
                <h4>{{ content.title }}</h4>
                <p>{{ content.description }}</p>
              </li>
            </ul>
            <p v-html="faqs.other"></p>
          </div>
        </div>
      </fieldset>

      <!-- Widget Section -->
      <fieldset v-if="widget.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Widget Section:
            </h4>
          </div>
          <div class="col-12">
            <h1 class="mb-3">
              {{ widget.title }}
            </h1>
            <p class="mb-3">
              {{ widget.description }}
            </p>
            <a :href="widget.btn_one.url" class="btn btn-success me-2">{{ widget.btn_one.text }}</a>
            <a :href="widget.btn_two.url" class="btn btn-dark">{{ widget.btn_two.text }}</a>
          </div>
        </div>
      </fieldset>

      <!-- Footer Section -->
      <fieldset v-if="footer.status" class="p-3 border-bottom border-bottom-light">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>
              Footer Section:
            </h4>
          </div>
          <div class="col-12">
            <h3 class="mb-3">
              {{ footer.title }}
            </h3>
            <div class="row">
              <div class="col">
                <h4>CMC Section</h4>
                <ul class="ml-3">
                  <li class="my-1" v-for="(content, index) in footer.cms" :key="index">
                    <a :href="content.url" class="btn btn-outline-secondry">{{ content.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="col">
                <h4>Action Section</h4>
                <ul class="ml-3">
                  <li class="my-1" v-for="(content, index) in footer.actions" :key="index">
                    <a :href="content.url" class="btn btn-outline-secondry">{{ content.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="col">
                <h4>Contact Section</h4>
                <div class="row">
                  <div class="col-12">
                    {{ footer.contact.email }}
                  </div>
                  <div class="col-12">
                    {{ footer.contact.phone }}
                  </div>
                  <div class="col-12" v-html="footer.contact.address"></div>
                  <div class="row mt-3">
                    <div class="col" v-for="(content, index) in footer.contact.social" :key="index" :class="!content.status ? 'd-none' : ''">
                      <a :href="content.url" class="btn btn-outline-dark">{{ content.title }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'MloLanding',
  data() {
    return {
      loading: true,
      id: 1,
      header: Object,
      why_choose: Object,
      current_rate: Object,
      loan_option: Object,
      how_it_work: Object,
      review: Object,
      rating: Object,
      lender: Object,
      team: Object,
      counter: Object,
      get_in_touch: Object,
      faqs: Object,
      widget: Object,
      footer: Object
    }
  },
  methods: {
    loadForm() {
      this.loading = true
      this.$http.get(`/api/v1/mlo/landing/${this.id}`)
        .then((res) => {
          this.header = res.data.header
          this.why_choose = res.data.why_choose
          this.current_rate = res.data.current_rate
          this.loan_option = res.data.loan_option
          this.how_it_work = res.data.how_it_work
          this.review = res.data.review
          this.rating = res.data.rating
          this.lender = res.data.lender
          this.team = res.data.team
          this.counter = res.data.counter
          this.get_in_touch = res.data.get_in_touch
          this.faqs = res.data.faqs
          this.widget = res.data.widget
          this.footer = res.data.footer
        })
        .catch((err) => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: err.response.statusText,
            status: 'error',
            delay: 2000
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  filters: {
    formateMonth: function (value) {
      if (value) {
        return moment(String(value)).format('MMM').toUpperCase()
      }
    }
  },
  created() {
    this.loadForm()
  }
}
</script>

<style lang="scss" scoped>
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
ul {
  padding: 0;
}
h4 {
  color: #6c757d;
}
.bold {
  font-weight: bold;
}
</style>
